import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';

const ProposalFormModal = ({ setOpen, insuranceProducts, setDownloadOpen, policyData, setPolicyData, validity }) => {
    console.log(insuranceProducts,"jsdbfjhb");
    const [form, setForm] = useState({
      PERSON_1_FIRST_NAME: "",
      PERSON_1_LAST_NAME: "",
      PERSON_1_DOB: "",
      PERSON_1_PINCODE: "",
      PERSON_1_CITY: "",
      PERSON_1_STATE: "",
      PERSON_1_MOBILE_NUMBER: "",
      PERSON_1_EMAIL_ID: "",
      START_DATE: "",
      
    });
    const [objectBody, setObjectBody] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const handleChange = (e) => {
  
      setForm({
        ...form,
        [e.target.name]: e.target.value
      });
      if (e.target.name === 'PERSON_1_DOB' || e.target.name === 'START_DATE') {
        setObjectBody({
          ...objectBody,
          [e.target.name]: changeDateFormatToMMMDYYYY(e.target.value)
        });
      } else {
        setObjectBody({
          ...objectBody,
          [e.target.name]: e.target.value,
        // take only numerical val from validty
        
        "DURATION_IN_DAYS": validity===null||validity.replace(/\D/g, '')==="0"?"1":validity.replace(/\D/g, ''),
        })
      }
  
    };
    const changeDateFormatToMMMDYYYY = (date) => {
      const d = new Date(date);
      const month = d.toLocaleString('default', { month: 'short' });
      return `${month} ${d.getDate()}, ${d.getFullYear()}`;
    };
  
    const handleClick = async () => {
      setIsLoading(true);
      try {
        
        const responseAuth = await fetch('https://api-uat.ensuredit.com/enbed/v1/auth/generate', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username: 'airtelDemo', password: 'airtelDemo' }),
        });
        const dataAuth = await responseAuth.json();
        let newPolicyData = [...policyData];
        for (let i = 0; i < insuranceProducts.length; i++) {
  
          const response = await fetch('https://api-uat.ensuredit.com/enbed/v1/policy-stores', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + dataAuth["accessToken"]
            },
            body: JSON.stringify({ product_id: insuranceProducts[i].id, proposal_form: objectBody })
          });
          
          const policy = await response.json();
          newPolicyData.push(policy);
          
          //call api to buy 
          const responseBuy = await fetch('https://api-uat.ensuredit.com/enbed/v1/products/buy/client', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + dataAuth["accessToken"]
            },
            body: JSON.stringify({ policy_id: policy.id })
          });
          const dataBuy = await responseBuy.json();
        }
        setPolicyData(newPolicyData);
        setOpen(false);
        setDownloadOpen(true);
        setIsLoading(false);
  
      } catch (err) {
        console.log(err);
      }
    };
  
    return (
      <div className="reserve">
        <div className="rContainer">
          <button
            style={
                {
                    border: "none",
                }
            }
            className="rClose"
            onClick={() => setOpen(false)}
          >
          x
          </button>
          <h3>Fill Your Details</h3>
          <form className="formGrid">
  
            <div>
                
              <label>
                First Name:
                <input type="text" name="PERSON_1_FIRST_NAME" value={form.PERSON_1_FIRST_NAME} onChange={handleChange} placeholder="First Name" />
              </label>
              <label>
                Last Name:
                <input type="text" name="PERSON_1_LAST_NAME" value={form.PERSON_1_LAST_NAME} onChange={handleChange} placeholder="Last Name" />
              </label>
              <label>
                Date of Birth:
                <input type="date" name="PERSON_1_DOB" value={form.PERSON_1_DOB} onChange={handleChange} placeholder="Date of Birth" />
              </label>
              <label>
                Pincode:
                <input type="text" name="PERSON_1_PINCODE" value={form.PERSON_1_PINCODE} onChange={handleChange} placeholder="Pincode" />
              </label>
            </div>
  
            <div>
              
              <label>
                City:
                <input type="text" name="PERSON_1_CITY" value={form.PERSON_1_CITY} onChange={handleChange} placeholder="City" />
              </label>
              <label>
                Mobile Number:
                <input type="text" name="PERSON_1_MOBILE_NUMBER" value={form.PERSON_1_MOBILE_NUMBER} onChange={handleChange} placeholder="Mobile Number" />
              </label>
              <label>
                Email ID:
                <input type="text" name="PERSON_1_EMAIL_ID" value={form.PERSON_1_EMAIL_ID} onChange={handleChange} placeholder="Email ID" />
              </label>
              <label>
                Start Date:
                <input type="date" name="START_DATE" value={form.START_DATE} onChange={handleChange} placeholder="Start Date" />
              </label>
            </div>
          </form>
          <div style={{minWidth: '100%', textAlign: 'center'}}>
            {isLoading ? <Spinner animation="border" /> :<button onClick={handleClick} className="rButton">
                Pay Now
            </button> }
          </div>
            
          
        </div>
      </div>
    );
  };

export default ProposalFormModal;