const bannerImages = [
  {
    id: 1,
    name: "Banner 1",
    image: "images/banner-1.jpg",
  },
  {
    id: 2,
    name: "Banner 2",
    image: "images/banner-2.jpg",
  },
  {
    id: 3,
    name: "Banner 3",
    image: "images/banner-3.jpg",
  },
  {
    id: 4,
    name: "Banner 4",
    image: "images/banner-4.jpg",
  },
  {
    id: 5,
    name: "Banner 5",
    image: "images/banner-5.jpg",
  },
  {
    id: 6,
    name: "Banner 6",
    image: "images/banner-6.jpg",
  },
];

export default bannerImages;
