const xstream = [
  {
    id: 1,
    name: "Enstream Box",
    image: "images/xstream-box.webp",
    btn: "BUY NOW",
  },
  {
    id: 2,
    name: "Enstream Fiber",
    image: "images/xstream-box.webp",
    btn: "BUY NOW",
  },
  {
    id: 3,
    name: "Enstream App",
    image: "images/xstream-app.webp",
    btn: "VIEW MORE",
  },
];

export default xstream;
