import { useState } from 'react';

import axios from 'axios';
import download from 'downloadjs';
import { useHistory } from "react-router-dom";
const DownloadPolicyModal = ({ setDownloadOpen, policyData }) => {
  //create a successful buy message if the buy is successful in buyPolicyData that is error is nil in buyPolicYDATA WHICH IS ALREADY THERE
  let history = useHistory();
  const [downloaded, setDownloaded] = useState(false);
  const [downloadMessage, setDownloadMessage] = useState('');



  const handleDownloadLink = async (pdfLinks) => {
    for (let index = 0; index < pdfLinks.length; index++) {
      const pdfUrl = pdfLinks[index];
      const response = await axios.get(pdfUrl, { responseType: 'blob' });
      download(response.data, `policy_${index + 1}.pdf`);
    }
  }

  const handleDownload = async () => {
    try {

      const responseAuth = await fetch('https://api-uat.ensuredit.com/enbed/v1/auth/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: 'airtelDemo', password: 'airtelDemo' }),
      });
      const dataAuth = await responseAuth.json();
      let newUrls = [];
      for (let i = 0; i < policyData.length; i++) {
        const response = await fetch('https://api-uat.ensuredit.com/enbed/v1/policy-stores/' + policyData[i].id + '/certificate:download', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + dataAuth["accessToken"]
          },
        });
        const data = await response.json();
        if (data.error) {
          setDownloadMessage(data.error);
        } else {
          
          
          // push data.url to an array
          newUrls.push(data.url);

          

          //navigate to a new page where a success message is shown

        }
      }
      setDownloadMessage('Policy downloaded successfully!');
      handleDownloadLink(newUrls);
      setDownloadOpen(false);
      setDownloaded(true);
      history.push('/success');
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="download-policy-modal">
      <div className="download-policy-modal-content">
        <div className="download-policy-modal-header">
          <h3>Download Policy</h3>
          <button
           onClick={() => setDownloadOpen(false)} >
            x
           </button>
        </div>
        <div className="download-policy-modal-body">
          {!policyData.error ? (
            <div>
              <button onClick={handleDownload}>Download</button>
            </div>
          ) : (
            <p>{policyData.error}</p>

          )}
          {downloaded && <p>{downloadMessage}</p>}
        </div>
      </div>
    </div>
  );

}

export default DownloadPolicyModal;