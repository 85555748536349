import React from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "./components/layout/Layout";
import GetAppPage from "./pages/GetAppPage";
import HomePage from "./pages/HomePage";
import PaymentPage from "./pages/PaymentPage";
import PlanTypeBill from "./pages/PlanTypeBill";
import RechargePage from "./pages/RechargePage";
import SuccessPage from "./pages/SuccessPage";

function App() {
  return (
    <Layout>
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route exact path="/getapp">
          <GetAppPage />
        </Route>
        <Route exact path="/recharge/:mobileNumber">
          <RechargePage />
        </Route>
        <Route exact path="/payment">
          <PaymentPage />
        </Route>
        <Route exact path="/planPage/:planType/:mobileNumber">
          <PlanTypeBill />
        </Route>
        <Route exact path="/success">
          <SuccessPage />
        </Route>
      </Switch>
    </Layout>
  );
}

export default App;
