const services = [
  {
    id: 1,
    name: "Buy Prepaid",
    image: "images/services-sim.svg",
  },
  {
    id: 2,
    name: "Buy Postpaid",
    image: "images/services-sim.svg",
  },
  {
    id: 3,
    name: "Buy Broadband",
    image: "images/services-broadband.svg",
  },
  {
    id: 4,
    name: "Buy DTH",
    image: "images/services-dth.svg",
  },
  {
    id: 5,
    name: "Buy Xstream",
    image: "images/services-xstream.svg",
  },
  {
    id: 6,
    name: "Port to Entelecom",
    image: "images/services-porttoairtel.svg",
  },
  {
    id: 7,
    name: "Prepaid to Postpaid",
    image: "images/services-pretopost.svg",
  },
  {
    id: 8,
    name: "Upgrade to Entelecom Black",
    image: "images/airtel-logo-new.svg",
  },
  {
    id: 9,
    name: "Entelecom Payments Bank",
    image: "images/airtel-payments-bank.webp",
  },
  {
    id: 10,
    name: "International Roaming",
    image: "images/services-ir.svg",
  },
  {
    id: 11,
    name: "Buisiness Postpaid",
    image: "images/service-business-postpaid.svg",
  },
  {
    id: 12,
    name: "Office Internet",
    image: "images/office_internet_dweb.svg",
  },
];

export default services;
