import { FunnelPlotOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import Packs from "../components/ui/Packs";
import PaymentPage from "./PaymentPage";
import { Container } from "react-bootstrap";

function RechargePage() {
  const [key, setKey] = useState("home");
  const [isPayment, setPayment] = useState(false);
  const [item, setItem] = useState({});
  const [isSelect, setIsSelect] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const planType =
    decodeURIComponent(queryParams.get("planType")) || "No plan type";
  console.log("planType === ", planType);
  // const { mobileNumber } = location.state || {};
  const { mobileNumber } = useParams();
  console.log("mobileNumber  ===", mobileNumber);
  useEffect(() => {
    console.log(isPayment, item);
  }, [isPayment, item]);
  return (
    <>
      {!isPayment && !["post-paid", "dth", "broadband"].includes(planType) ? (
        <Container>
          <div style={{ display: "flex" }}>
          <div
            style={{
              width: "40%",
              height: "auto",
              background: "#e8e8eb",
              display: "flex",
              justifyContent: "end",
            }}>
            <div style={{ marginTop: "2rem", marginRight: "2rem" }}>
              <div
                style={{
                  alignItems: "center",
                  backgroundColor: "#fff",
                  display: "flex",
                  width: "350px",
                  height: "65px",
                  marginBottom: "30px",
                  borderRadius: "16px",
                  padding: "1rem",
                  justifyContent: "space-between",
                }}>
                <span
                  style={{
                    fontSize: "16px",
                    lineHeight: "18px",
                    whiteSpace: "nowrap",
                    fontWeight: 700,
                    fontFamily: "TondoBold, sans-serif",
                  }}>
                  {mobileNumber || 9492215675}
                </span>
                <button
                  style={{
                    backgroundColor: "transparent",
                    color: "#027bfc",
                    border: "none",
                    outline: "none",
                    fontWeight: 700,
                  }}>
                  Change
                </button>
              </div>
              <div
                style={{
                  alignItems: "center",
                  backgroundColor: "#fff",
                  display: "flex",
                  width: "350px",
                  marginBottom: "30px",
                  borderRadius: "16px",
                  padding: "1rem",
                  justifyContent: "space-between",
                }}>
                <div
                  style={{
                    color: "#696b6f",
                    fontSize: "12px",
                    lineHeight: "18px",
                    fontWeight: "700",
                    fontFamily: "TondoBold, sans-serif",
                    width: "100%",
                  }}>
                  <p>FIND A PREFERRED RECHARGE PACK</p>
                  <div
                    style={{
                      width: "100%",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "space-around",
                    }}>
                    <Input
                      suffix={<SearchOutlined />}
                      placeholder="Search for a pack or add-ons"
                      style={{
                        borderRadius: "8px",
                        width: "250px",
                        height: "50px",
                      }}
                    />
                    <Button
                      style={{
                        borderRadius: "8px",
                        height: "50px",
                      }}>
                      <FunnelPlotOutlined />
                    </Button>
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
          <div style={{ width: "70%", height: "auto", padding: "2rem" }}>
            <div>
              <div
                style={{
                  color: "#696b6f",
                  fontSize: "24px",
                  lineHeight: "28px",
                  fontWeight: "700",
                  fontFamily: "TondoBold, sans-serif",
                  paddingBottom: "1rem",
                }}>
                Recharge Packs
              </div>
              <div>
                {/* <Tabs defaultActiveKey="1" items={items} onChange={onChange} /> */}
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3">
                  <Tab eventKey="home" title="Data">
                    <Packs
                      item={item}
                      setItem={setItem}
                      setPayment={setPayment}
                    />
                  </Tab>
                  <Tab eventKey="profile" title="Profile">
                    Tab content for Profile
                  </Tab>
                  <Tab eventKey="contact" title="Contact">
                    Tab content for Contact
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        </Container>
        
      ) : (
        <PaymentPage
          item={item}
          setIsSelect={setIsSelect}
          isSelect={isSelect}
          planType={planType}
        />
      )}
    </>
  );
}

export default RechargePage;
