import { Button } from "@mui/material";
import React, { useState } from "react";
import { Col, Container, Form, Image, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const RechargeSection = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [field, setField] = useState("prepaid");
  let history = useHistory();
  const handleClick = () => {
    if (mobileNumber) {
      if (field === "prepaid") {
        history.push(`/recharge/${encodeURIComponent(mobileNumber)}`);
      } else if (field === "post-paid") {
        history.push(`/planPage/post-paid/${encodeURIComponent(mobileNumber)}`);
      } else if (field === "dth") {
        history.push(`/planPage/dth/${encodeURIComponent(mobileNumber)}`);
      } else if (field === "broadband") {
        history.push(`/planPage/broadband/${encodeURIComponent(mobileNumber)}`);
      }
    }
  };
  const setMobileNumberData = (value) => {
    const inputValue = value?.target?.value;
    if ((Number(inputValue) || inputValue === "") && inputValue?.length <= 10) {
      setMobileNumber(inputValue);
    }
  };
  return (
    <div className="recharge-section">
      <Container>
        <Row className="justify-content-center">
          <Col lg={6} md={12} sm={12} xl={6}>
            <h2 className="recharge-head">Recharge & Pay Bills</h2>
            <p className="recharge-text">Get exciting cashback and offers</p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={6} md={12} sm={12} xl={6}>
            <Row>
              <Col>
                <div className="recharge-btn-list">
                  <Button
                    variant="outlined"
                    className={
                      field === "prepaid"
                        ? "main-change-btn-selected main-change-btn"
                        : "main-change-btn"
                    }
                    onClick={() => {
                      setField("prepaid");
                    }}>
                    <Image
                      src="images/services-sim.svg"
                      className="small-btn-recharge"
                      fluid></Image>{" "}
                    <span className="btn-text-sec">Prepaid</span>
                  </Button>
                  <Button
                    variant="outlined"
                    className={
                      field === "post-paid"
                        ? "main-change-btn-selected main-change-btn"
                        : "main-change-btn"
                    }
                    onClick={() => {
                      setField("post-paid");
                    }}>
                    <Image
                      src="images/services-sim.svg"
                      className="btn-img-sec"
                      fluid></Image>
                    <span className="btn-text-sec"> Postpaid</span>
                  </Button>
                  <Button
                    variant="outlined"
                    className={
                      field === "dth"
                        ? "main-change-btn-selected main-change-btn"
                        : "main-change-btn"
                    }
                    onClick={() => {
                      setField("dth");
                    }}>
                    <Image
                      src="images/services-broadband.svg"
                      className="btn-img-sec"
                      fluid></Image>
                    <span className="btn-text-sec"> DTH</span>
                  </Button>
                  <Button
                    variant="outlined"
                    className={
                      field === "broadband"
                        ? "main-change-btn-selected main-change-btn"
                        : "main-change-btn"
                    }
                    onClick={() => {
                      setField("broadband");
                    }}>
                    <Image
                      src="images/services-dth.svg"
                      className="btn-img-sec"
                      fluid></Image>
                    <span className="btn-text-sec"> Broadband</span>
                  </Button>
                  {/* <Button
                    variant="outlined"
                    className={
                      field === "airtelBlack"
                        ? "main-change-btn-selected main-change-btn"
                        : "main-change-btn"
                    }
                    onClick={() => {
                      setField("airtelBlack");
                    }}>
                    <Image
                      src="images/airtel-black.svg"
                      className="btn-img-sec"
                      fluid></Image>
                    <span className="btn-text-sec"> Airtel Black</span>
                  </Button> */}
                </div>
              </Col>
            </Row>
            <Row className="search-section">
              <Col>
                <Form.Control
                  value={mobileNumber}
                  type="text"
                  placeholder="Enter Mobile Number"
                  className="search-input"
                  onChange={(e) => setMobileNumberData(e)}
                />
              </Col>
            </Row>
            <Row className="search-section justify-content-center">
              <Button
                variant="contained"
                className="btn-recharge"
                style={{ maxWidth: "30%" }}
                onClick={handleClick}>
                Recharge
              </Button>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* add a red component with an offer just like<div class="wt-row offer-section-row unset" data-layout="standard"><div class="wt-col" data-layout="standard"><div class="offer-item"><img src="https://assets.airtel.in/static-assets/home-recast/images/offer-icon.png" loading="lazy" class="wt-img offer-icon" width="36" height="36" alt="offer icon" draggable="false"><p class="wt-typography offer-text" data-type="body-para-xs-bold">Free 2 GB data coupons on select recharges via Thanks app</p><button id="getAppButton" class="wt-btn get-app-btn" data-type="alternate-light" data-size="sm" data-icon-position="left" data-version="fixed-padding" data-control-state="none"><span class="wt-btn-text">GET APP</span></button></div></div></div> */}

      <Row className="offer-section">
        <Col>
          <div className="offer-item">
            <Image
              src="images/airtel-logo-new.svg"
              className="offer-icon"
              fluid></Image>
            <p className="offer-text1">
              Free 2 GB data coupons on select recharges via Thanks app
            </p>
            <Button variant="contained" className="get-app-btn">
              GET APP
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RechargeSection;
