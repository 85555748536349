import { CalendarFilled, RightOutlined } from "@ant-design/icons";
import { MobiledataOffOutlined } from "@mui/icons-material";
import { Button, Divider } from "antd";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

function Packs({ item, setItem, setPayment }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setPayment(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [data, setData] = useState([
    {
      amount: 39,
      data: "Unlimited",
      days: "1 day",
      insurance: {
        id: "7d77a418-09ca-4394-b74e-0452341cedb5",
        name: "Screen Protection",
        benefits: "Covers screen damage up to Rs. 1000",
        price: "Rs. 10",
        validity: "1 day",
      },
    },
    {
      amount: 69,
      data: "Unlimited",
      days: "2 days",
      insurance: {
        id: "968dd35b-67a8-48ac-bd33-86985d591a3c",
        name: "Screen Protection Plus",
        benefits: "Covers screen damage up to Rs. 1500",
        price: "Rs. 20",
        validity: "2 days",
      },
    },
    {
      amount: 99,
      data: "13 GB",
      days: "4 days",
      insurance: {
        id: "888750dc-79f4-4ebd-8964-38b2d1c9e223",
        name: "Device Theft Protection",
        benefits: "Covers theft up to Rs. 5000",
        price: "Rs. 30",
        validity: "4 days",
      },
    },
    {
      amount: 199,
      data: "15 GB",
      days: "7 days",
      insurance: {
        id: "0f67746b-cc32-48ac-aa7e-5ac5758d9b1d",
        name: "Weekly Device Insurance",
        benefits: "Covers damage and theft up to Rs. 10000",
        price: "Rs. 50",
        validity: "7 days",
      },
    },
    {
      amount: 429,
      data: "20 GB",
      days: "14 days",
      insurance: {
        id: "243622d5-efe0-4a96-88ea-4c601f0a1caa",
        name: "Bi-weekly Device Insurance",
        benefits: "Covers damage and theft up to Rs. 15000",
        price: "Rs. 80",
        validity: "14 days",
      },
    },
    {
      amount: 559,
      data: "Unlimited",
      days: "14 days",
      insurance: {
        id: "29d3eba2-78ff-4631-865a-b158b6db72e5",
        name: "Extended Device Protection",
        benefits: "Covers damage and theft up to Rs. 20000",
        price: "Rs. 100",
        validity: "14 days",
      },
    },
    {
      amount: 799,
      data: "1.5 GB/day",
      days: "28 days",
      insurance: {
        id: "b0a3b447-d605-423f-9312-79bc434c3065",
        name: "Monthly Device Insurance",
        benefits: "Covers damage and theft up to Rs. 25000",
        price: "Rs. 150",
        validity: "28 days",
      },
    },
    {
      amount: 949,
      data: "2.5 GB/day",
      days: "56 days",
      insurance: {
        id: "2864de4e-035b-4fc2-a70f-356285cf0234",
        name: "Bimonthly Device Insurance",
        benefits: "Covers damage and theft up to Rs. 30000",
        price: "Rs. 250",
        validity: "56 days",
      },
    },
    {
      amount: 1499,
      data: "2 GB/day",
      days: "84 days",
      insurance: {
        id: "f155117e-d1dc-4fb9-a29f-ee3134ea9734",
        name: "Quarterly Device Insurance",
        benefits: "Covers damage and theft up to Rs. 40000",
        price: "Rs. 350",
        validity: "84 days",
      },
    },
    {
      amount: 29,
      data: "5 GB",
      days: "1 day",
      insurance: {
        id: "56409bef-2021-49f7-ab49-7139cbc02d99",
        name: "Basic Screen Protection",
        benefits: "Covers screen damage up to Rs. 800",
        price: "Rs. 5",
        validity: "1 day",
      },
    },
  ]);
  return (
    <div
      style={{
        height: "400px",
        overflow: "scroll",
      }}>
      {data.map((item, index) => {
        return (
          <div
            key={index}
            style={{
              width: "720px",
              padding: "20px",
              borderRadius: "16px",
              backgroundColor: "#fff",
              border: "1px solid #e8e8eb",
              // boxShadow: "none",
              boxShadow: "6px 20px 50px rgba(1, 49, 101, 0.1)",
              marginBottom: "10px",
            }}>
            <Container>
              <Row>
                <Col>
                  <span
                    style={{
                      fontSize: "14px",
                      lineHeight: "16px",
                      letterSpacing: "-0.005em",
                      whiteSpace: "nowrap",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontDisplay: "swap",
                      fontFamily: "TondoBold, sans-serif",
                    }}>
                    ₹ {item.amount}
                  </span>
                </Col>
                <Col>
                  {" "}
                  <span
                    style={{
                      fontSize: "14px",
                      lineHeight: "16px",
                      letterSpacing: "-0.005em",
                      whiteSpace: "nowrap",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontDisplay: "swap",
                      fontFamily: "TondoBold, sans-serif",
                    }}>
                    {item.data}
                  </span>{" "}
                </Col>
                <Col
                  style={{
                    justifyContent: "end",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  {" "}
                  <span
                    style={{
                      fontSize: "14px",
                      lineHeight: "16px",
                      marginRight: "4px",
                      letterSpacing: "-0.005em",
                      whiteSpace: "nowrap",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontDisplay: "swap",
                      fontFamily: "TondoBold, sans-serif",
                    }}>
                    {item.days}
                  </span>{" "}
                  validity
                </Col>
                <Col
                  style={{
                    justifyContent: "end",
                    display: "flex",
                    color: "#027bfc",
                  }}>
                  <RightOutlined />
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col
                  style={{
                    display: "inline-grid",
                    justifyContent: "end",
                    width: "100%",
                  }}>
                  <Button
                    style={{
                      color: "#027bfc",
                      fontSize: "14px",
                      lineHeight: "16px",
                      letterSpacing: "-0.005em",
                      whiteSpace: "nowrap",
                      fontStyle: "normal",
                      fontWeight: "550",
                      fontDisplay: "swap",
                      fontFamily: "TondoBold, sans-serif",
                    }}
                    onClick={() => {
                      showModal();
                      setItem(item);
                    }}>
                    View Details
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        );
      })}

      <Modal
        title="Basic Modal"
        show={isModalOpen}
        onOk={handleOk}
        onHide={handleCancel}>
        <Modal.Header
          closeButton
          style={{ borderBottom: "none" }}></Modal.Header>
        <Modal.Body
          style={{
            width: "650px",
            marginBottom: "100px",
          }}>
          <div
            style={{
              fontSize: "44px",
              lineHeight: "56px",
              letterSpacing: "-0.4px",
              fontStyle: "normal",
              fontWeight: "700",
              fontDisplay: "swap",
              fontFamily: "TondoBold, sans-serif",
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}>
            ₹{item.amount}
          </div>
          <div
            style={{
              width: "600px",
              justifyContent: "space-around",
              display: "flex",
              background: "#ffff",
              height: "50px",
              marginBottom: "30px",
              borderRadius: "10px",
            }}>
            <div
              style={{
                alignItems: "center",
                display: "flex",
              }}>
              <MobiledataOffOutlined style={{ marginRight: "10px" }} />{" "}
              <span>{item.data}</span>
            </div>
            <div
              style={{
                alignItems: "center",
                display: "flex",
              }}>
              <CalendarFilled style={{ marginRight: "10px" }} />{" "}
              <span>{item.days}</span>
            </div>
          </div>
          <Accordion flush style={{ width: "600px", borderRadius: "10px" }}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>TERMS & CONDITIONS</Accordion.Header>
              <Accordion.Body>
                <p>Post 20GB usage per day data speed will be up to 64Kbps</p>
                <span>Terms & Conditions</span>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Modal.Body>
        <Modal.Footer
          style={{
            width: "100%",
            justifyContent: "center",
            borderTop: "none",
            paddingBottom: "30px",
          }}>
          <Button
            variant="primary"
            onClick={handleOk}
            style={{
              width: "600px",
              height: "50px",
              borderRadius: "10px",
              backgroundColor: "black",
              color: "white",
            }}>
            RECHARGE NOW
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Packs;
