import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { Accordion, Spinner } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DownloadPolicyModal from "../components/ui/DownloadPolicyModal.js";
import ProposalFormModal from "../components/ui/ProposalFormModal.js";
import { Container } from "react-bootstrap";

function PaymentPage({ item, setIsSelect, isSelect, planType }) {
  let history = useHistory();
  const [selectionType, setSelectionType] = useState("checkbox");
  const [isLoding, setIsLoading] = useState(true);
  const [rowData, setRowData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [policyData, setPolicyData] = useState([]);
  const { mobileNumber } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const postPaidAmount =
    decodeURIComponent(queryParams.get("amount")) || "No plan type";
  console.log("postPaidAmount === ", postPaidAmount);
  let tableData = [];
  let planName = "";
  try {
    if (planType === "broadband") {
      planName = "Broadband Bill";
      tableData = [
        {
          id : "123cc7a6-79c9-4147-b9fc-79d3ac3a4453",
          name: "Broadband Protection - Basic Plan",
          benefits: "Equipment Repair Coverage upto Rs. 500",
          price: "Rs. 40",
          
        },
      ];
    } else if (planType === "post-paid") {
      planName = "Postpaid Bill";
      //if to int postPaidAmount >200 or > 500 or > 1000
      let postPaidAmountInt = parseInt(postPaidAmount);
      if (postPaidAmountInt < 200) {
        tableData = [
          
          {
            id: "0e4ddf41-68f1-4eda-bb7b-2aead8670284",
            name: "Secure Shield Basic Plan",
            benefits: "Accidental Damage, Liquid Damage Protection upto Rs. 500",
            price: "Rs. 30",
          }
        ];
      } else if (postPaidAmountInt < 500) {
        tableData = [
          {
            id: "0e4ddf41-68f1-4eda-bb7b-2aead8670284",
            name: "Secure Shield Gold Plan",
            benefits: "Accidental Damage, Liquid Damage Protection upto Rs. 1000",
            price: "Rs. 60",
          }
          
        ];
      } else {
        tableData = [
          {
            id: "0e4ddf41-68f1-4eda-bb7b-2aead8670284",
            name: "Secure Shield Platinum Plan",
            benefits: "Accidental Damage, Liquid Damage Protection upto Rs. 5000",
            price: "Rs. 100",
          }
        ];
      }
    } else if (planType === "dth") {
      planName = "DTH Bill";
      let postPaidAmountInt = parseInt(postPaidAmount);
      if (postPaidAmountInt < 200) {
        tableData = [
          {
            id: "1942e104-1985-4fb3-b736-e2927b67050f",
            name: "DTH Recharge Protection - Basic Plan",
            benefits:
              "Equipment Repair Coverage upto Rs. 500",
            price: "Rs. 30",
          }
        ];
      } else if (postPaidAmountInt < 500) {
        tableData = [
          {
            id: "0c9ccf98-c761-4bb8-8109-f582d8c98281",
            name: "DTH Recharge Protection - Standard Plan",
            benefits:
              "Equipment Repair Coverage upto Rs. 1000",
            price: "Rs. 60",
          },
        ];
      } else {
        tableData = [
          {
            id: "d0273538-94c9-41fa-861e-eea27894b2cc",
            name: "DTH Recharge Protection - Premium Plan",
            benefits:
              "Equipment Repair Coverage upto Rs. 2000",
            price: "Rs. 90",
          },
        ];
      }
    } else {
      planName = "Prepaid Recharge";
      tableData = [
        {
          id: item?.insurance?.id,
          name: item?.insurance?.name,
          benefits: item?.insurance?.benefits,
          price: item?.insurance?.price,
        },
      ];
    }
  } catch (error) {
    tableData = [
      {
        id: item?.insurance?.id,
        name: item?.insurance?.name,
        benefits: item?.insurance?.benefits,
        price: item?.insurance?.price,
      },
    ];
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    console.log("isSelect", isSelect);
  }, [isSelect]);

  const columns = [
    {
      title: "Insurance Name",
      dataIndex: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Benefits",
      dataIndex: "benefits",
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setIsSelect(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <Container>
      <div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div>
          <div
            style={{
              margin: "32px 0 18px",
              fontWeight: "700",
              fontSize: "20px",
              lineHeight: "20px",
            }}>
            Payment options
          </div>
          <Accordion flush style={{ width: "600px" }}>
            <Accordion.Item
              eventKey="0"
              style={{ background: "var(--off-white-color) !imporatant" }}>
              <Accordion.Header>Credit/Debit/ATM Card</Accordion.Header>
              <Accordion.Body>
                <p>Post 20GB usage per day data speed will be up to 64Kbps</p>
                <span>Terms & Conditions</span>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Net Banking</Accordion.Header>
              <Accordion.Body>
                <p>Post 20GB usage per day data speed will be up to 64Kbps</p>
                <span>Terms & Conditions</span>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>UPI</Accordion.Header>
              <Accordion.Body>
                <p>Post 20GB usage per day data speed will be up to 64Kbps</p>
                <span>Terms & Conditions</span>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Wallets</Accordion.Header>
              <Accordion.Body>
                <p>Post 20GB usage per day data speed will be up to 64Kbps</p>
                <span>Terms & Conditions</span>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div style={{ width: "420px" }}>
          <div
            style={{
              margin: "32px 0 18px",
              fontWeight: "700",
              fontSize: "20px",
              lineHeight: "20px",
            }}>
            Payment Summary
          </div>
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#eef4ff",
              borderRadius: "8px",
              overflow: "hidden",
              maxHeight: "1000px",
              transition: "max-height .25s cubic-bezier(1,0,0,1)",
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "16px 12px 0",
                flexShrink: "0",
              }}>
              <h4
                style={{
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "19px",
                  flex: "1 1",
                }}>
                {["post-paid", "dth", "broadband"]?.includes(planType)
                  ? planName
                  : "Prepaid"}{" "}
                | {mobileNumber || 9492215675}
              </h4>
              <div style={{ display: "flex", marginLeft: "4px" }}>
                <h4>₹{item.amount || postPaidAmount}</h4>
              </div>
            </div>

            <div
              style={{
                position: "relative",
                paddingBottom: "16px",
                marginTop: "4px",
              }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  padding: "0 12px",
                }}>
                <div style={{ flex: "1 1" }}>
                  {["post-paid", "dth", "broadband"]?.includes(planType) ? (
                    <p>Bill Payment ₹{postPaidAmount}</p>
                  ) : (
                    <p>Data</p>
                  )}
                  <p style={{ color: "var(--dark-color)", lineHeight: "18px" }}>
                    {item.data} {item.days}
                  </p>
                </div>
              </div>
            </div>
            {isSelect.length > 0 ? (
              <div
                style={{
                  borderTop: ".5px solid var(--grey-secondary)",
                  borderBottom: ".5px dashed var(--grey-dark-color)",
                  padding: "12px 18px 14px",
                }}>
                <div
                  style={{
                    display: "flex",
                    color: "var(--grey-dark-color)",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <h6>{isSelect[0]?.name}</h6>
                  <h6>
                    ₹
                    {Number(isSelect[0]?.price?.split(" ")[1])
                      ? Number(isSelect[0]?.price?.split(" ")[1])
                      : isSelect[0].price}
                  </h6>
                </div>
              </div>
            ) : null}
            <div
              style={{
                borderTop: ".5px solid var(--grey-secondary)",
                borderBottom: ".5px dashed var(--grey-dark-color)",
                padding: "12px 18px 14px",
              }}>
              <div
                style={{
                  display: "flex",
                  color: "var(--grey-dark-color)",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <h5>Total</h5>
                <h6>
                  ₹
                  {isSelect.length
                    ? item?.amount
                      ? Number(isSelect[0]?.price?.split(" ")[1]) + item.amount
                      : Number(isSelect[0]?.price?.split(" ")[1]) +
                        Number(postPaidAmount)
                    : item.amount || postPaidAmount}
                </h6>
              </div>
              {/* add a buy now button */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "16px",
                }}>
                <button
                  style={{
                    backgroundColor: "#3498db",
                    color: "white",
                    padding: "12px 24px",
                    borderRadius: "8px",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    isSelect.length == 0
                      ? history.push("/success")
                      : setIsModalOpen(true)
                  }>
                  Pay Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      
      {!isLoding ? (
        <div
          style={{ justifyContent: "center", display: "flex", width: "900px" }}>
          <div>
            <Table
              bordered
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              columns={columns}
              dataSource={tableData}
            />
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Spinner animation="border" />
        </div>
      )}
      {isModalOpen && (
        <ProposalFormModal
          setOpen={setIsModalOpen}
          insuranceProducts={isSelect}
          setDownloadOpen={setIsDownloadModalOpen}
          policyData={policyData}
          setPolicyData={setPolicyData}
          validity={item?.days}
          // Pass the selected product data
        />
      )}
      {isDownloadModalOpen && (
        <DownloadPolicyModal
          setOpen={isDownloadModalOpen}
          setDownloadOpen={setIsDownloadModalOpen}
          policyData={policyData}
        />
      )}
    </Container>
  );
}

export default PaymentPage;
