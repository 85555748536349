import { Button } from "@mui/material";
import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

const SwitchToAirtel = () => {
  return (
    <div className="switch-container">
      <div
        className="wt-row switch-your-number justify-content-center"
        data-layout="standard"
        id="buyNewConnection">
        <img
          src="images/airtel-logo-new.svg"
          loading="lazy"
          className="wt-img switch-image"
          draggable="false"
          alt="switch to airtel"
        />

        <div className="wt-row switch-to-airtel-content">
          <div className="wt-col switch-text">
            <label className="wt-typography">Switch to entelecom</label>
          </div>
          <div className="wt-col get-sim-text">
            <label className="wt-typography">
              Select a plan & order SIM • Get SIM home-delivered
            </label>
          </div>
          <div className="wt-col col-sm-12 col-md-4">
            <div className="wt-row justify-content-end">
              <button
                data-testid="switchToAirtel-button"
                className="wt-btn get-started-btn"
                data-type="alternate-light"
                data-size="sm"
                data-icon-position="left"
                data-version="fixed-padding"
                data-control-state="none">
                <span className="wt-btn-text">GET STARTED</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const NewConnectionSection = () => {
  return (
    <div className="newConnection-section">
      <Container>
        <Row className="justify-content-center">
          <Col lg={6} md={12} sm={12} xl={6}>
            <h2 className="newConnection-head">Buy A New Connection</h2>
            <p className="newConnection-text">
              Get exciting cashback and offers
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={6} md={12} sm={12} xl={6}>
            <Row>
              <Col>
                <div className="newConnection-btn-list">
                  <Button variant="outlined" className="main-change-btn">
                    <Image
                      src="images/services-sim.svg"
                      className="small-btn-newConnection"
                      fluid></Image>{" "}
                    <span className="btn-text-sec">Prepaid</span>
                  </Button>
                  <Button variant="outlined" className="main-change-btn">
                    <Image
                      src="images/services-sim.svg"
                      className="btn-img-sec"
                      fluid></Image>
                    <span className="btn-text-sec"> Postpaid</span>
                  </Button>
                  <Button variant="outlined" className="main-change-btn">
                    <Image
                      src="images/services-broadband.svg"
                      className="btn-img-sec"
                      fluid></Image>
                    <span className="btn-text-sec"> DTH</span>
                  </Button>
                  <Button variant="outlined" className="main-change-btn">
                    <Image
                      src="images/services-dth.svg"
                      className="btn-img-sec"
                      fluid></Image>
                    <span className="btn-text-sec"> Broadband</span>
                  </Button>
                  <Button variant="outlined" className="main-change-btn">
                    <Image
                      src="images/airtel-logo-new.svg"
                      className="btn-img-sec"
                      fluid></Image>
                    <span className="btn-text-sec"> Entelecom Black</span>
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* <div class="wt-row switch-your-number unset justify-content-center" data-layout="standard" id="buyNewConnection"><img src="https://assets.airtel.in/static-assets/home-recast/images/switch-to-airtel-web.png" loading="lazy" class="wt-img switch-image" draggable="false" alt="switch to airtel image"><div class="wt-row switch-to-airtel-column unset no-margin" data-layout="standard"><div class="wt-col col-sm-12 col-md-8 no-gutter" data-layout="standard"><div class="wt-row switch-to-airtel-content row-as-column no-margin" data-layout="standard"><div class="wt-col switch-text no-gutter" data-layout="standard"><label class="wt-typography" data-type="body-single-line-md-bold" style="color: rgb(246, 246, 246);">Switch to airtel</label></div><div class="wt-col get-sim-text no-gutter" data-layout="standard"><label class="wt-typography" data-type="body-single-line-xs" style="color: rgb(246, 246, 246);">Select a plan &amp; order SIM • Get SIM home-delivered</label></div></div></div><div class="wt-col col-sm-12 col-md-4 no-gutter" data-layout="standard"><div class="wt-row unset no-margin justify-content-end" data-layout="standard"><button data-testid="switchToAirtel-button" class="wt-btn get-started-btn" data-type="alternate-light" data-size="sm" data-icon-position="left" data-version="fixed-padding" data-control-state="none"><span class="wt-btn-text">GET STARTED</span></button></div></div></div></div> */}
      <SwitchToAirtel />
    </div>
  );
};

export default NewConnectionSection;
