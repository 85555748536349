import { Button } from "@mui/material";
import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

const PlanTypeBill = () => {
  const boo = useParams();
  console.log("boo === ", boo);
  const { mobileNumber: paramNumber, planType } = boo;
  const history = useHistory();

  const [mobileNumber, setMobileNumber] = useState(paramNumber);
  const [amount, setAmount] = useState("");

  const handleClick = () => {
    if (mobileNumber && amount) {
      history.push(
        `/recharge/${encodeURIComponent(
          mobileNumber
        )}?planType=${planType}&amount=${amount}`
      );
    }
  };
  const setMobileNumberData = (value) => {
    const inputValue = value?.target?.value;
    if ((Number(inputValue) || inputValue === "") && inputValue?.length <= 10) {
      setMobileNumber(inputValue);
    }
  };

  const setAmountData = (value) => {
    const inputValue = value?.target?.value;
    if ((Number(inputValue) || inputValue === "") && inputValue?.length <= 7) {
      setAmount(inputValue);
    }
  };

  return (
    <div className="recharge-section">
      <Container>
        <Row className="justify-content-center">
          <Col lg={6} md={12} sm={12} xl={6}>
            <h2 className="recharge-head">Recharge & Pay Bills</h2>
            <p className="recharge-text">Get exciting cashback and offers</p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm={{ span: 5, offset: 1 }}>
            <Row className="search-section">
              <Col>
                <Form.Control
                  value={mobileNumber}
                  type="text"
                  placeholder="Enter Mobile Number"
                  className="search-input"
                  onChange={(e) => setMobileNumberData(e)}
                />
              </Col>
            </Row>
            <Row className="search-section">
              <Col>
                <Form.Control
                  value={amount}
                  type="text"
                  placeholder="Enter Amount"
                  className="search-input"
                  onChange={(e) => setAmountData(e)}
                />
              </Col>
            </Row>
            <Row className="search-section justify-content-center">
              <Col sm={{ offset: 3 }}>
                <Button
                  disabled={mobileNumber.length !== 10 && !amount}
                  variant="contained"
                  className="btn-recharge"
                  style={{ maxWidth: "30%" }}
                  onClick={handleClick}>
                  PAY BILL
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PlanTypeBill;
