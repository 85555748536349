
import React from "react";


function SuccessPage() {
    return (
        <div style={{ 
            textAlign: "center", 
            margin: "20px", 
            padding: "20px", 
            backgroundColor: "#f9f9f9", 
            borderRadius: "10px", 
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", 
        }}>
            <h1 style={{ 
                color: "green", 
                fontSize: "2em", 
                marginBottom: "20px" 
            }}>
                Payment successful
            </h1>
            <p style={{ 
                fontSize: "1.2em", 
                lineHeight: "1.6" 
            }}>
                Thank you for choosing our service. 
                <br />
                Your payment has been done successfully. 
                <br />
                If you have any questions or need further assistance,
                <br />
                please contact our support team.
            </p>
        </div>
    );
}

export default SuccessPage;
